// extracted by mini-css-extract-plugin
export var container = "HighlightBlock-module--container--cae53";
export var content = "HighlightBlock-module--content--b9e88";
export var contentBottom = "HighlightBlock-module--contentBottom--4da13";
export var hitArea = "HighlightBlock-module--hitArea--f8427";
export var layoutFullSize = "HighlightBlock-module--layoutFullSize--36497";
export var layoutTextLeft = "HighlightBlock-module--layoutTextLeft--963ec";
export var layoutTextRight = "HighlightBlock-module--layoutTextRight--b6833";
export var layoutTile = "HighlightBlock-module--layoutTile--7082b";
export var mediaArea = "HighlightBlock-module--mediaArea--959cc";
export var text = "HighlightBlock-module--text--867b7";
export var textArea = "HighlightBlock-module--textArea--b3442";
export var textLayoutCont = "HighlightBlock-module--textLayoutCont--311db";
export var title = "HighlightBlock-module--title--53c72";