import React, { Children, ReactNode } from "react"
import { CSSTransition } from "react-transition-group"
import * as styles from "./Accordion.module.scss"

type AccordionStatelessProps = {
  title?: ReactNode
  categoryTitle?: ReactNode

  /** Initially open */
  open?: boolean
  children: ReactNode
}

const AccordionStateless = (props: AccordionStatelessProps) => {
  return (
    <>
      <div className={`${styles.container} ${!props.open ? styles.closed : ""}`}>
        {(props.title || props.categoryTitle) && (
          <div>
            {props.categoryTitle && <div className={styles.category}>{props.categoryTitle}</div>}
            <div className={styles.title}>{props.title}</div>
          </div>
        )}
        <CSSTransition in={props.open} classNames="dropdown" timeout={600} unmountOnExit>
          <div>
            {!!Children.toArray(props.children).length && <div className={styles.content}>{props.children}</div>}
          </div>
        </CSSTransition>
      </div>
    </>
  )
}

export default AccordionStateless
