import { useState } from "react"

type List = string[]

/**
 * Adds or removes given element from or to the list
 */
type Toggle = (id: string) => void

type Clear = () => void

/**
 * A list of items and a function to eiher add or remove from that list.
 * @param initialList --
 */
export function useToggleList(initialList: List = []): [List, Toggle, Clear] {
  const [items, setItems] = useState(initialList)

  const toggle: Toggle = id => {
    if (items.includes(id)) {
      setItems(items.filter(itm => itm !== id))
    } else {
      setItems([...items, id])
    }
  }

  const clear: Clear = () => {
    setItems([])
  }

  return [items, toggle, clear]
}
