import { graphql, PageProps } from "gatsby"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import ArticleCategoryPage from "../components/pages/ArticleCategoryPage"
import { DatoLocale } from "../types"

/** @deprecated */
const DatoArticleCategory = (
  props: PageProps<Queries.DatoArticleCategoryPageQuery, Queries.DatoArticleCategoryPageQueryVariables>
) => {
  //console.log("Rendering DatoArticleCategory:", props)

  const { data } = props

  if (!data) {
    console.error("No data for gatsby-template datoArticleCategory")
    return null
  }

  return (
    <DefaultLayout
      mainMenu={data.website?.mainMenu}
      footer={data.website?.footer}
      seoMetaTags={data.category?.seoMetaTags}>
      <ArticleCategoryPage
        locale={props.pageContext.locale as DatoLocale}
        originalId={props.pageContext.originalId}
        category={data.category}
      />
    </DefaultLayout>
  )
}

export const query = graphql`
  # $originalId parametri viittaa lokalisoimattomaan route recordiin
  query DatoArticleCategoryPage($originalId: String!, $locale: String!, $fallbackLocales: [String!]) {
    website: datoCmsWebsite(locale: $locale, fallbackLocales: $fallbackLocales) {
      mainMenu {
        ...DatoMainNavTree
      }
      footer {
        ...DatoFooter
      }
    }
    category: datoCmsArticleCategory(
      originalId: { eq: $originalId }
      locale: $locale
      fallbackLocales: $fallbackLocales
    ) {
      ...DatoArticleCategory
    }
  }
`

export default DatoArticleCategory
