import { graphql, useStaticQuery } from "gatsby"
import { DatoLocale } from "../types"

/**
 * DatoCMS record ID
 */
type ArticleTagId = string

/**
 * Article Tag id to Tag label mapping. Localised,
 */
type ArticleTagMap = Map<ArticleTagId, string>

const store = new Map<DatoLocale, ArticleTagMap>()

export function useArticleTags(locale: DatoLocale): ArticleTagMap {
  const data = useStaticQuery<Queries.ArticleTagsQuery>(graphql`
    query ArticleTags {
      tag: allDatoCmsArticleTag {
        nodes {
          id: originalId
          labels: _allLabelLocales {
            locale
            value
          }
        }
      }
    }
  `)

  const cached = store.get(locale)
  if (cached) {
    return cached
  }

  const result = new Map<ArticleTagId, string>()

  data.tag.nodes.forEach(tag => {
    if (!tag.id || !tag.labels) {
      return
    }
    const label = tag.labels.find(name => name?.locale === locale)?.value
    if (!label) {
      return
    }
    result.set(tag.id, label)
  })
  store.set(locale, result)

  return result
}
