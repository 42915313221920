import { graphql, Link } from "gatsby"
import React from "react"
import useArticlePath from "../../hooks/useArticlePath"
import { useArticles } from "../../hooks/useArticles"
import { useArticleTags } from "../../hooks/useArticleTag"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import useRoutePath from "../../hooks/useRoutePath"
import { useToggleList } from "../../hooks/useToggleList"
import { DatoLocale } from "../../types"
import ToggleButton from "../atoms/ToggleButton"
import BgColor from "../layouts/BgColor"
import ArticleFilter from "../molecules/ArticleFilter"
import * as styles from "./ArticleCategoryPage.module.scss"

type ArticleCategoryPageProps = {
  category: Queries.DatoArticleCategoryFragment | null
  locale: DatoLocale
  originalId: string
}

/**
 * This isn't used in prod. All "automatically from category" generated pages are manually rebuild using Page content
 * @deprecated
 */
const ArticleCategoryPage = (props: ArticleCategoryPageProps) => {
  // console.debug("Rendering ArticleCategoryPage", props)

  const { locale, originalId } = props
  const t = useDatoMicrocopy("article", locale)
  const getArticlePath = useArticlePath()
  const [tags, toggleTag] = useToggleList([])
  const tagMap = useArticleTags(locale)

  const getRoutePath = useRoutePath()
  // Route record id of "Article root route" from https://harvia.admin.datocms.com/editor/item_types/522768/items/17948632/edit
  // Hard coded to avoid deprecated useWebsite()
  const rootPath = getRoutePath("17980659")

  let articles = useArticles(locale)

  const { category } = props
  if (!category) {
    return null
  }

  // filter by category
  articles = articles.filter(article => article.articleCategory?.id === originalId)

  // filter by availability on website
  articles = articles.filter(article => !!getArticlePath(article.id))

  // filter by tags
  if (tags?.length) {
    articles = articles.filter(article =>
      tags.find(tag => article.articleTags?.find(articleTag => articleTag?.id === tag))
    )
  }

  return (
    <div>
      <BgColor color="BrandGreyWarmLight">
        <div className={`${styles.header} layout-container`}>
          {rootPath && <Link to={rootPath}>&lt; {t("backToRootPage", "Back to articles front page")}</Link>}
          <h1>{category.name}</h1>

          <div className={styles.buttons}>
            {Array.from(tagMap).map(([id, label]) => {
              return (
                <span key={id} className={styles.button}>
                  <ToggleButton size="flat" label={label} onClick={() => toggleTag(id)} active={tags.includes(id)} />
                </span>
              )
            })}
          </div>
        </div>
      </BgColor>
      <div>
        <ArticleFilter articles={articles} pageSize={16} />
      </div>
    </div>
  )
}

export default ArticleCategoryPage

export const query = graphql`
  fragment DatoArticleCategory on DatoCmsArticleCategory {
    seoMetaTags {
      tags
    }
    name
  }
`
