import tokens from "@nitro/harvia-style-dictionary/tokens/base.json"
import { Link } from "gatsby"
import React, { ReactNode } from "react"
import { isBrowser } from "../../../utils"
import BgColor from "../../layouts/BgColor"
import AccordionStateless from "../AccordionStateless"
import * as styles from "./HighlightBlock.module.scss"

type HighlightBlockProps = {
  id?: string
  title?: string | null
  titleAs?: "h2" | "h3" | "span"
  categoryTitle?: string
  media: ReactNode
  content: ReactNode
  /** defaults to "Tile" */
  layout?: HighlightBlockLayout | null
  contentPosition?: "Top" | "Bottom"
  linkTo?: string
}

export type HighlightBlockLayout = "Tile" | "FullSize" | "TextLeft" | "TextRight"

const HighlightBlock = (props: HighlightBlockProps) => {
  //console.log('Rendering HighlightBlock', props)

  /*
    Ei piiloteta tekstiä mobiilissa, SSR:ssä eikä muilla kun "Tile" layoutilla
  */
  const isMobile = isBrowser && window.innerWidth < parseFloat(tokens.breakpoint.md.value)
  const disableHover = !isBrowser || isMobile
  const [open, setOpen] = React.useState<boolean>(disableHover)

  const { layout = "Tile", contentPosition = "Top" } = props

  const Title = props.titleAs || "span"

  return (
    <Link
      id={props.id}
      to={props.linkTo || ""}
      onFocus={() => setOpen(true)}
      onPointerEnter={() => setOpen(true)}
      onPointerLeave={() => setOpen(disableHover)}
      className={`${styles.container} ${styles[`content${contentPosition}`]} ${styles[`layout${layout}`]}`}>
      <div className={`${styles.mediaArea}`}>{props.media}</div>
      <div className={styles.hitArea} />

      <BgColor color="SemiTransparent" className={styles.textArea}>
        <div className={`layout-container ${styles.content}`}>
          <AccordionStateless
            open={open}
            categoryTitle={props.categoryTitle}
            title={props.title && <Title className={styles.title}>{props.title}</Title>}>
            {props.content}
          </AccordionStateless>
        </div>
      </BgColor>
    </Link>
  )
}

export default HighlightBlock
