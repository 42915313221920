import React from "react"
import { IoIosCheckmark } from "react-icons/io"
import Button, { ButtonProps } from "./Button"
import * as styles from "./ToggleButton.module.scss"

type ToggleButtonProps = { active: boolean } & ButtonProps

const ToggleButton = (props: ToggleButtonProps) => {
  //console.log('Rendering ToggleButton', props)

  const { active, ...rest } = props

  return (
    <Button
      {...rest}
      label={
        active ? (
          <span style={{ position: "relative" }}>
            <IoIosCheckmark className={styles.mark} />
            {rest.label}
          </span>
        ) : (
          rest.label
        )
      }
    />
  )
}

export default ToggleButton
